import SVG from 'components/SVG'
import React from 'react'

const Load = (pros) => {
  return (
    <SVG viewBox="0 0 262 43.18" {...pros}>
      <g>
        <path strokeDasharray={630} d="M0,27.88H32l5.93,14.23L48.61,16l6.23,14.22H66.39v-19L72,4.17h6.68l4.93,7.11v16.6H87V16l16.87-5.93V33.38l19.48-.14L111.25,21.18l0,0c-.15-.14-.3-.26-.45-.41A11.45,11.45,0,1,1,127,4.56l.4.45,0,0,0,0,.41-.45a11.45,11.45,0,0,1,16.19,16.19c-.14.15-.3.27-.45.41l0,0L131.46,33.35l20.29,0v-29h3.81V1.07h11.79V4.34h5.27v23H176V17.74l11.8,5.4V35.79h3.69V13.66h12.43V31.4h6.75L217,42.11l5.93-21.34L230,31.44h32" fill="none" stroke="currentColor" strokeWidth="1.19"/>
      </g>
    </SVG>
  )
}

export default Load
