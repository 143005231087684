import { useEffect, useRef, useState } from 'react'
import useFirebase from './useFirebase'

const KEY = '_FIREBASE_FIRESTORE'

const useFirestore = () => {
  const firebase = useFirebase()
  const firestoreRef = useRef()
  const [firestore, setFirestore] = useState(firestoreRef.current)

  useEffect(() => {
    if (window[KEY]) {
      firestoreRef.current = window[KEY]
      setFirestore(window[KEY])
    } else if (firebase) {
      import('firebase/firestore').then(({ getFirestore, connectFirestoreEmulator, ...rest }) => {
        const firestore = getFirestore(firebase)
        // if (process.env.NODE_ENV === 'development') {
        //   connectFirestoreEmulator(firestore, 'localhost', 8080)
        // }
        window[KEY] = {
          firestore,
          ...rest,
        }
        firestoreRef.current = window[KEY]
        setFirestore(window[KEY])
      }).catch(e => {
        console.error(e)
      })
    }
  }, [firebase])

  return firestore
}

export default useFirestore
