import { Box, Center } from '@chakra-ui/react'
import React from 'react'

const BlockModule = ({
    footer,
    city,
    startPercent,
    percent,
    children,
    repeat,
    bg,
    bgTop,
    bgColor,
    backgroundSize,
    bgPos,
    ...props
  }) => {
  return (
    <Center pos="relative" overflow="hidden" {...props}>
      {city}
      <Box
        pos={bgPos || "absolute"}
        top={0}
        left={0}
        right={0}
        bottom={0}
        bgGradient={`linear(to-b, #f08f95 ${startPercent || 0}%, ${bgColor || 'rgba(255,255,255,0.55)'} ${percent || 100}%)`}
      />
      <Box
        pos={bgPos || "absolute"}
        top={bgTop || 0}
        left={0}
        right={0}
        bottom={0}
        backgroundImage={`url(${bg})`}
        backgroundSize={backgroundSize}
        backgroundRepeat={repeat}
      />
      {children}
      {footer}
    </Center>
  )
}

export default BlockModule
