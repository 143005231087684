import sortBy from 'lodash/sortBy'
import data from '../../google-sheet/data.json'

const getResult = (choices = [], care) => {
  const cityScores = choices.reduce(
    (scores, { category, values }) =>
      values.reduce((scores, name) => {
        const found = data.find(
          (d) => d.category === category && d.name === name
        )
        if (found) {
          const multiplier = care && care.includes(name) ? 2 : 1
          return found.values.reduce((scores, { value, city }) => {
            scores[city] = scores[city] || { categories: {}, score: 0 }
            if (!value) {
              console.log(`${category}.${name}`, value)
            }
            scores[city].score += value * multiplier

            scores[city].categories[category] = scores[city].categories[
              category
            ] || { details: [], score: 0 }
            scores[city].categories[category].score += value * multiplier
            scores[city].categories[category].details.push({
              name,
              value,
            })
            return scores
          }, scores)
        }
        return scores
      }, scores),
    {}
  )
  return sortBy(Object.entries(cityScores), ([, { score }]) => score).reverse().slice(0, 5)
}

export default getResult
