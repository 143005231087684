import React, { useEffect } from 'react'
import { Box, Center, Container, keyframes, Text } from '@chakra-ui/react'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'

import useHeader from 'contexts/header/useHeader'
import { responsive } from 'contexts/responsive'
import Load from './Load'
import bg from './loading_bg.png'
import BlockModule from 'components/BlockModule'
import useFirestore from 'hooks/useFirestore'
import useUtm from 'hooks/useUtm'
import getResult from 'utils/getResult'

const draw = keyframes`
  0% {
    stroke-dashoffset: 630;
  }
  100% {
    stroke-dashoffset: 0;
  }
`
const Animate = styled(Box)`
  path {
    animation: ${draw} 0.5s ease-in-out infinite;
  }
`

const Loading = ({ location }) => {
  const utm = useUtm()
  const firestore = useFirestore()
  const { setHideHeader } = useHeader()
  useEffect(() => {
    setHideHeader(true)
  }, [])
  useEffect(() => {
    if (firestore) {
      if (location.state?.important) {
        // console.log('care', location.state?.important)
        const { addDoc, collection, firestore: db, serverTimestamp } = firestore
        const choices = collection(db, 'choices')
        const result = getResult(location.state?.important, location.state?.care)
        const task = async () => {
          try {
            await addDoc(choices, {
              care: location.state.important.reduce(
                (all, c) =>
                  all.concat(c.values.map((v) => `${c.category}/${v}`)),
                []
              ),
              resident: location.state.live,
              timestamp: serverTimestamp(),
              userAgent: navigator.userAgent,
              referrer: document.referrer,
              result: result[0][0],
              utm,
            })
          } catch (e) {
            console.error(e)
          }
          navigate('/result', {
            state: {
              ...location.state,
              result,
            },
          })
        }
        task()
      } else {
        navigate('/')
      }
    }
  }, [firestore])
  return (
    <BlockModule
      backgroundSize="contain"
      repeat={'no-repeat'}
      bgColor={'rgba(246, 190, 193, 0.81)'}
      percent={100}
      bg={bg}
    >
      <Container as={Center} flexDir={'column'}>
        <Box pos="relative" width="70%">
          <Load width="100%" color="#fff" />
          <Animate>
            <Load
              pos="absolute"
              left="0"
              top="0"
              width="100%"
              color="textColor"
            />
          </Animate>
        </Box>
        <Text
          color="white"
          fontWeight={700}
          fontSize={responsive('1.375em', '1.5em')}
          mt="0.625rem"
        >
          速配中⋯⋯
        </Text>
      </Container>
    </BlockModule>
  )
}

export default Loading
